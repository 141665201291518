import { ResponsiveLayout, Separator, ThemeColor, ThemeShade, ModalButton } from '@papa/components';
import styled from 'styled-components';
import { ItalicSubtext, PartnerMailToLink } from '../Pages/Form/FormHelpers';
import { Blurb, Flex } from '../styles/FormElements';
import dropins from '../emails/dropins.json';
const FooterLayout = styled(ResponsiveLayout.Root)`
    &${ResponsiveLayout.Container} {
        background-color: ${props => props.theme.secondary.main};
        color: ${props => props.theme.white};
        font-family: ${props => props.theme.font.main};
    }

    ${ResponsiveLayout.Content} {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        min-height: 4rem;
    }
`;

const StylishEabert = styled.img`
    object-fit: contain;
    width: 100%;
`;

const FooterSeparator = styled(Separator.Root)`
    min-height: 4rem;
` as typeof Separator.Root;

export const Footer = () => {
    return (
        <footer>
            <FooterLayout>
                <img src="/eab-logo-white.svg" alt="EAB logo" height="64px" width="150px" />
                <LinkAndAddress color={ThemeColor.neutral} shade={ThemeShade.light} />
            </FooterLayout>
        </footer>
    );
};

interface ILinkAndAddressProps {
    color: keyof typeof ThemeColor;
    shade: keyof typeof ThemeShade;
}
export const LinkAndAddress = (props: ILinkAndAddressProps) => {
    const { color, shade } = props;
    return (
        <Flex>
            <div style={{ alignSelf: 'center', textAlign: 'right', padding: '0 0.7rem' }}>
                <Blurb color={color} shade={shade}>
                    {dropins.partner_name}
                </Blurb>
                <Blurb color={color} shade={shade}>
                    {dropins.partner_address_1}
                </Blurb>
                <Blurb color={color} shade={shade}>
                    {dropins.partner_address_2}
                </Blurb>
            </div>
            <FooterSeparator orientation={Separator.Orientations.vertical} color={ThemeColor.error} shade={ThemeShade.light} weight="thin" />
            <div style={{ alignSelf: 'center', display: 'block' }}>
                <StyledHelpLink color={color} shade={shade} />
                <StyledPrivacyButton color={color} shade={shade} />
            </div>
        </Flex>
    );
};

interface IStyledModalProps {
    className?: string;
}
const HelpLink = (props: IStyledModalProps) => {
    return (
        <ModalButton.Root
            leadingIcon="help"
            buttonText="Technical Help"
            modalHeader="Technical Help"
            size="xs"
            className={props.className}
            modalContent={
                <>
                    <Blurb color={ThemeColor.secondary} shade={ThemeShade.main}>
                        Having issues? Give us a shout! {PartnerMailToLink}
                    </Blurb>
                    <StylishEabert alt="Eabert chilling with pizza on the golf course" src="/chill-eabert.png" />
                    <br />
                    <br />
                    <ItalicSubtext>
                        The above email address is not monitored. If you actually need help, please reach out to the appropriate product owner or open a ticket.
                    </ItalicSubtext>
                </>
            }
        />
    );
};

const PrivacyLink = (props: IStyledModalProps) => {
    const currentYear = new Date().getFullYear();
    const copyrightYear = `© ${currentYear}`;

    return (
        <ModalButton.Root
            leadingIcon="lock"
            buttonText="Privacy"
            modalHeader="Privacy"
            size="m"
            className={props.className}
            modalContent={
                <>
                    This {dropins.web_site} uses a "cookie," a small text file that is sent to your computer when you first visit. The cookie is stored on your computer
                    and allows you to be recognized when you visit again later. We use cookies to enhance your visits, most often by prefilling your name and other
                    information on forms you submit to us.
                    <br />
                    <br />
                    This {dropins.web_site} uses remarketing services, such as Google, Facebook and Adroll, to advertise on third-party {dropins.web_site}s to previous
                    visitors of our site. Third-party vendors place cookies on users' browsers for targeted advertising purposes and serve ads to locations such as the
                    Google search results page or any site in the Google Display Network based on someone's past visits to the {dropins.web_site}. Data such as
                    identification of your internet browser or computer operating system may be collected for targeted advertising. This information is collected to track
                    usage behavior and compile aggregate data to facilitate content improvements. You may{' '}
                    <a href="https://accounts.google.com/InteractiveLogin/signinchooser?continue=https%3A%2F%2Fadssettings.google.com%2Fu%2F0%2Fauthenticated&followup=https%3A%2F%2Fadssettings.google.com%2Fu%2F0%2Fauthenticated&passive=1209600&ifkv=AQMjQ7RQjImF3M86zHMbbQmy2CS6WvYkQFUCAdXOQhlI2L4K22apTteldbqp1ZmDosrr4QvJp_Rgdw&flowName=GlifWebSignIn&flowEntry=ServiceLogin">
                        opt out of Google's use of cookies by visiting Google's Ads Settings
                    </a>{' '}
                    and <a href="https://optout.networkadvertising.org/?c=1">opt out of the additional use of cookies by visiting this site</a>.
                    <br />
                    <br />
                    Google Drive: When you visit certain EAB {dropins.web_site}s, we may allow users with Google Drive accounts to attach documents from their Google
                    Drive Account to a submitted college enrollment application. The EAB {dropins.web_site} receives documents selected from a user's Google Drive account
                    during the application process. Such documents are stored temporarily and included in the user's complete, delivered application.
                    <br />
                    <br />
                    You can view the complete privacy policy for {dropins.partner_name}.
                    <br />
                    <br />
                    <i>
                        {copyrightYear} {dropins.copyright}
                    </i>
                </>
            }
        />
    );
};

export const StyledHelpLink = styled(HelpLink)<ILinkAndAddressProps>`
    &${ModalButton.Trigger} {
        display: flex;
        align-items: center;
        color: ${props => props.theme[props.color][props.shade]};
        font-family: ${props => props.theme.font.main};
        gap: 0.2rem;
    }
`;

export const StyledPrivacyButton = styled(PrivacyLink)<ILinkAndAddressProps>`
    &${ModalButton.Trigger} {
        display: flex;
        align-items: center;
        color: ${props => props.theme[props.color][props.shade]};
        font-family: ${props => props.theme.font.main};
        gap: 0.2rem;
    }
`;
